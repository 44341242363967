<template>
  <div>
    <!-- 
  <div class="info d-flex  flex-column">
    <span>User: {{ user }}</span>
    <span>Domain: {{ currDomain }}</span>
    <span>Filter: {{ filter }}</span>
    <span>URL: {{ url }}</span>
    <span>Inspections: {{ generalInspections.length }}</span>
  </div> -->
    <transition name="fade">
      <div
        v-if="inspectionsIsLoading"
        name="dark-overlay"
        class="dark-overlay rounded-4 d-flex justify-content-center align-items-center"
      >
        <div class="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </transition>
    <span
      v-if="navIsLoading"
      class="loader-linear"
      ref="loader"
      data-loader="red"
    >
    </span>
    <transition name="slide-fade-left">
      <div v-show="objectState" class="objects" id="objects" ref="appObjects">
        <app-objects :features="features" :w="objectsWidth" :h="objectsHeight">
        </app-objects>
      </div>
    </transition>
    <transition name="slide-fade-right">
      <div
        :w="390"
        :h="500"
        :resizable="false"
        style="border: none; z-index: 2"
        ref="legenda"
        v-show="legendaState"
        id="legenda"
        class="position-absolute legenda-container"
      >
        <app-legenda />
      </div>
    </transition>

    <div v-show="objectView" ref="videoPlayer" class="video-player p-3">
      <video-player></video-player>
    </div>
    <div v-show="objectView" ref="appInspection" class="app-inspection p-3">
      <app-inspection></app-inspection>
    </div>
    <div ref="map" class="map p-3">
      <Map @features="onFeaturesAdded"></Map>
    </div>
    <div
      v-show="objectView"
      @mousedown="mouseDown($event)"
      ref="horizontal"
      id="horizontal"
      class="horizontal position-absolute"
    ></div>
    <div
      v-show="objectView"
      @mousedown="mouseDown($event)"
      ref="vertical"
      id="vertical"
      class="vertical position-absolute"
    ></div>

    <transition name="fade">
      <div
        v-if="editGeneralForm"
        style="position: absolute; z-index: 5; width: 100%; height: 100%"
      >
        <app-edit-general-form></app-edit-general-form>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="newGeneralForm"
        style="position: absolute; z-index: 5; width: 100%; height: 100%"
      >
        <app-general-form></app-general-form>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {getterTypes as playerGetterTypes} from '@/store/modules/player'
import {getterTypes as mapGetterTypes} from '@/store/modules/map'
import {getterTypes as navGetterTypes} from '@/store/modules/nav'
import {getterTypes as inspectionsGetterTypes} from '@/store/modules/inspections'
import {getterTypes as authGetterTypes} from '@/store/modules/auth'
import {actionTypes as inspectionsActionTypes} from '@/store/modules/inspections'
import {mutationTypes as navMutationTypes} from '@/store/modules/nav'
import {mutationTypes as playerMutationTypes} from '@/store/modules/player'
import AppInspection from '@/components/inspections/Inspections.vue'
import AppObjects from '@/components/map/Objects.vue'
import VideoPlayer from '@/components/player/VideoPlayer.vue'
import AppLegenda from '@/components/map/Legenda.vue'
import AppEditGeneralForm from '@/components/inspections/EditGeneralForm.vue'
import AppGeneralForm from '@/components/inspections/GeneralForm.vue'
import Map from '@/views/Map.vue'
import gsap from 'gsap'
import Draggable from 'gsap/Draggable'
import {getItem} from '@/helpers/persistanceStorage'
gsap.registerPlugin(Draggable)

export default {
  name: 'AppMappContainer',
  data() {
    return {
      mouseIsDown: false,
      target: '',
      videoURL: '',
      objectView: false,
      features: null,
      objectsWidth: null,
      objectsHeight: null,
      objectsAreLoading: false,
      user: getItem('user'),
      currDomain: getItem('curr-domain'),
      filter: '',
    }
  },
  computed: {
    ...mapGetters({
      objectState: navGetterTypes.objectState,
      legendaState: navGetterTypes.legendaState,
      objectClicked: inspectionsGetterTypes.objectClicked,
      editGeneralForm: inspectionsGetterTypes.editGeneralForm,
      newGeneralForm: inspectionsGetterTypes.newGeneralForm,
      generalInspections: inspectionsGetterTypes.generalInspections,
      inspectionsIsLoading: inspectionsGetterTypes.isLoading,
      domain: authGetterTypes.domain,
      navIsLoading: navGetterTypes.isLoading,
      filters: mapGetterTypes.filtersState,
      filterAdded: mapGetterTypes.filterAdded,
      url: playerGetterTypes.url,
    }),
  },
  components: {
    AppInspection,
    AppObjects,
    VideoPlayer,
    Map,
    AppLegenda,
    AppEditGeneralForm,
    AppGeneralForm,
  },

  watch: {
    filterAdded(val) {
      if (val) {
        this.filter = this.filters.find((f) => f.filter_id === val).filter_name
      }
    },
    legendaState(val) {
      if (val) {
        this.initDraggable(document.getElementById('legenda'), 'legenda')
      }
    },
    objectClicked(obj) {
      if (obj !== null) {
        if (!this.objectState) {
          this.$store.commit(navMutationTypes.objectChange, true)
        }
        this.objectView = true
      } else {
        setTimeout(() => {
          this.$store.commit(navMutationTypes.legendaChange, true)
          this.$store.commit(navMutationTypes.objectChange, true)
        }, 200)
      }
    },
    objectView(val) {
      if (val) {
        gsap.set(this.$refs.appObjects, {clearProps: true})
        this.$store.commit(navMutationTypes.legendaChange, false)
        gsap.to(this.$refs.map, {
          width: '50%',
          height: '50%',
          left: '50%',
          top: '50%',
          ease: 'power4.easeOut',
        })
        setTimeout(() => {
          gsap.to(this.$refs.vertical, {
            delay: 0.5,
            duration: 1.5,
            height: '100%',
            opacity: 1,
          })
          gsap.to(this.$refs.horizontal, {
            delay: 0.5,
            duration: 1.5,
            width: '100%',
            opacity: 1,
          })
          gsap.set(this.$refs.horizontal, {
            y: '50%',
          })
          gsap.set(this.$refs.vertical, {
            x: '50%',
          })
          gsap.to(this.$refs.videoPlayer, {
            width: '50%',
            height: '50%',
            ease: 'power4.easeOut',
          })
          gsap.to(this.$refs.appObjects, {
            top: '20px',
            width: '48%',
            height: '47%',
            left: '51%',
            ease: 'power4.easeOut',
          })
          this.objectsWidth = 47
          this.objectsHeight = 47
          gsap.to(this.$refs.appInspection, {
            width: '50%',
            height: '50%',
            top: '50%',
            ease: 'power4.easeOut',
          })
        }, 10)
      }
    },
    objectState(val) {
      if (val === false && this.objectView === true) {
        gsap.to(this.$refs.vertical, {
          duration: 0.5,
          opacity: 0,
        })
        gsap.to(this.$refs.horizontal, {
          duration: 0.5,
          opacity: 0,
        })
        gsap.to(this.$refs.map, {
          width: '100%',
          height: '100%',
          left: '0%',
          top: '0%',
          ease: 'power4.easeInOut',
        })
        gsap.to(this.$refs.videoPlayer, {
          width: '5%',
          height: '5%',
          ease: 'power4.easeIn',
        })
        gsap.set(this.$refs.appObjects, {
          left: '30px',
          width: '390px',
          height: '500px',
          top: '100px',
        })
        this.objectsHeight = null
        this.objectsWidth = null
        gsap.to(this.$refs.appInspection, {
          width: '5%',
          height: '5%',
          top: '100%',
          ease: 'power4.easeIn',
          onComplete: this.removeObjectView,
        })
      } else {
        this.initDraggable(document.getElementById('objects'), 'objects')
      }
    },
  },

  methods: {
    initDraggable(object, component) {
      this.draggable = Draggable.create(object, {
        type: 'x,y',
        trigger: '.drag-' + component,
        onDragStart: () => {},
        onMove: () => {},
        onDragEnd: (e) => {},
      })
    },
    onFeaturesAdded(features) {
      this.features = features
    },
    mouseDown(event) {
      event.preventDefault()
      this.mouseIsDown = true
      this.target = event.target.id
    },
    mouseIsMoving(event) {
      if (this.mouseIsDown) {
        if (this.target === 'horizontal') {
          let percentage = (100 * event.clientY) / window.innerHeight
          gsap.set(this.$refs.horizontal, {
            y: event.clientY - window.innerHeight / 2,
          })
          gsap.set(this.$refs.videoPlayer, {
            height: percentage + '%',
          })
          this.objectsHeight = percentage - 3
          gsap.set(this.$refs.appObjects, {
            height: percentage - 3 + '%',
          })
          gsap.set(this.$refs.appInspection, {
            top: percentage + '%',
            height: 100 - percentage + '%',
          })
          gsap.set(this.$refs.map, {
            top: percentage + '%',
            height: 100 - percentage + '%',
          })
        }
        if (this.target === 'vertical') {
          let percentage = (100 * event.clientX) / window.innerWidth
          gsap.set(this.$refs.vertical, {
            x: event.clientX - window.innerWidth / 2,
          })
          gsap.set(this.$refs.videoPlayer, {
            width: percentage + '%',
          })
          gsap.set(this.$refs.appInspection, {
            width: percentage + '%',
          })
          this.objectsWidth = 100 - percentage - 3
          gsap.set(this.$refs.appObjects, {
            left: percentage + 1 + '%',
            width: 100 - percentage - 3 + '%',
          })
          gsap.set(this.$refs.map, {
            left: percentage + '%',

            width: 100 - percentage + '%',
          })
        }
      }
    },
    mouseUp() {
      this.mouseIsDown = false
    },
    removeObjectView() {
      this.objectView = false
    },
  },
  mounted() {
    window.addEventListener('mousemove', this.mouseIsMoving)
    window.addEventListener('mouseup', this.mouseUp)
  },
}
</script>
<style>
.horizontal {
  top: 50%;
  height: 10px;
  width: 5%;
  border-top: 1px dashed #c2cfe3;
  background-color: transparent;
  cursor: n-resize;
  opacity: 1;
}
.vertical {
  width: 10px;
  height: 5%;
  left: 50%;
  border-left: 1px dashed #c2cfe3;
  background-color: transparent;
  cursor: e-resize;
  opacity: 1;
}
.video-player {
  position: absolute;
  z-index: 3;
  width: 5%;
  height: 5%;
  background: white;
  margin-left: auto;
  margin-right: auto;
}
.app-objects {
  position: absolute;
  width: 5%;
  height: 5%;
  background: white;
  left: 100%;
}
.app-inspection {
  position: absolute;
  width: 5%;
  height: 5%;
  background: white;
  top: 100%;
}
.map {
  /* position: absolute;
    width: 50%;
    height: 50%;
    background: white;
    left: 50%;
    top: 50%; */
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
}
/* //////////////////////////////////// */
.slide-fade-left-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-left-leave-active {
  transition: all 0.1s ease-in;
}
.slide-fade-left-enter, .slide-fade-left-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-20px);
  opacity: 0;
}
/* /////////////////////////////////// */
.slide-fade-right-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-right-leave-active {
  transition: all 0.1s ease-in;
}
.slide-fade-right-enter, .slide-fade-right-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}
/* /////////////////////////////////// */
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  transition: all 0.3s ease-in;
}
.fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: var(--dark-blue-color);
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}
.dark-overlay {
  position: absolute;
  width: 99vw;
  height: 98vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  cursor: pointer;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.objects {
  position: absolute;
  width: 390px;
  height: 500px;
  left: 30px;
  top: 100px;
  z-index: 2;
}
.loader-linear {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: absolute;
  z-index: 99;
  bottom: 0px;
  background-color: white;
  overflow: hidden;
}

.loader-linear::after {
  content: '';
  width: 192px;
  height: 4.8px;
  /* background-color: v-bind(loaderColor); */
  background-color: #2c97d6;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }

  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
.info {
  position: absolute;
  top: 0;
  right: 100px;
  z-index: 100;
  background: white;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #c2cfe3;
  margin: 10px;
  font-size: 10px;
}
</style>
