<template>
  <div>
    <div class="row">
      <div class="close-button d-flex align-items-end">
        <div style="width: 100%">
          <button
            class="btn p-0 rounded-circle"
            @click.prevent.stop="onClose"
            style="position: relative; left: 98%"
          >
            <span class="material-icons text-dark fw-bold">clear</span>
          </button>
        </div>
      </div>
      <div ref="container" style="height: 800px; overflow-y: scroll">
        <form ref="form" enctype="multipart/form-data" class="bg-white p-4">
          <div class="row">
            <!-- IMAGE -->
            <div class="col-md-12">
              <div
                v-if="showImage"
                class="d-flex align-items-center flex-column"
              >
                <div ref="canvasContainer">
                  <!-- CANVAS -->
                  <canvas
                    v-if="showImage"
                    width="800"
                    height="600"
                    style="border: 1px solid #d3d3d3; color: red; z-index: 1"
                    class="rounded-4"
                    ref="myCanvas"
                    @mousemove="canvasMouseMove"
                    @mousedown="canvasMouseDown"
                    @mouseup="canvasMouseUp"
                    @mouseout="canvasMouseOut"
                  >
                    Your browser does not support the HTML5 canvas tag.
                  </canvas>
                  <!-- TEXT AREA -->
                  <textarea
                    ref="textField"
                    v-if="writeTextClick"
                    style="
                      position: fixed;
                      display: none;
                      font-weight: bold !important;
                      background-color: rgba(221, 232, 255, 0.3);
                      border: none !important;
                      outline: none !important;
                    "
                    @blur="saveTextField"
                  ></textarea>
                  <!-- RESIZE DIV -->
                  <div
                    class="item"
                    ref="rect"
                    v-if="resizeClicked"
                    :style="{width: canvasWidth, height: canvasHeight}"
                  >
                    <div class="resizer ne" @mousedown="onResizeDown"></div>
                    <div class="resizer nw" @mousedown="onResizeDown"></div>
                    <div class="resizer sw" @mousedown="onResizeDown"></div>
                    <div class="resizer se" @mousedown="onResizeDown"></div>
                  </div>
                </div>

                <!-- CONTROLS -->
                <div class="row" style="position: relative">
                  <!-- REMOVE IMAGE -->
                  <div
                    style="cursor: pointer"
                    class="border rounded-4 mx-1 button-menu m-3"
                    title="Verwijder screenshot"
                    @click.stop.prevent="onRemoveImage()"
                  >
                    <a>
                      <span
                        class="material-icons"
                        style="color: #212529; margin-left: 24px"
                        role="button"
                        >delete_outlined</span
                      >
                    </a>
                  </div>
                  <!-- PENCIL ICON -->
                  <div
                    style="cursor: pointer"
                    class="border rounded-4 mx-1 button-menu m-3"
                    :class="{inactive: !freeDraw, activeIcon: freeDraw}"
                    title="Vrij tekenen"
                    @click.stop.prevent="onFreeDraw()"
                  >
                    <a>
                      <span
                        class="material-icons"
                        style="color: #212529"
                        role="button"
                        >edit</span
                      >
                    </a>
                  </div>
                  <!-- CIRCLE ICON -->
                  <div
                    style="cursor: pointer"
                    class="border rounded-4 mx-1 button-menu m-3"
                    :class="{inactive: !circleDraw, activeIcon: circleDraw}"
                    title="Teken een cirkel"
                    @click.stop.prevent="onCircleDraw()"
                  >
                    <a>
                      <span class="material-icons"> panorama_fish_eye </span>
                    </a>
                  </div>
                  <!-- TEXT FIELD -->
                  <div
                    style="cursor: pointer"
                    class="border rounded-4 mx-1 button-menu m-3"
                    :class="{inactive: !writeText, activeIcon: writeText}"
                    title="Schrijf tekst"
                    @click.stop.prevent="onWriteText()"
                  >
                    <a>
                      <span class="material-icons"> title </span>
                    </a>
                  </div>

                  <!-- COLOR PICKER -->
                  <div
                    class="border rounded-3"
                    :style="{
                      width: '37px',
                      height: '37px',
                      background: color,
                      marginTop: '18px',
                      marginLeft: '10px',
                      marginRight: '10px',
                    }"
                    @click="showColorpicker"
                  ></div>
                  <!-- REFRESH -->
                  <div
                    style="cursor: pointer"
                    class="border rounded-4 mx-1 button-menu m-3"
                    title="Reset canvas"
                    @click.stop.prevent="onRefresh()"
                  >
                    <a>
                      <span class="material-icons"> refresh </span>
                    </a>
                  </div>
                  <!-- RESIZE -->
                  <!-- <div
                  style="cursor: pointer"
                  class="border rounded-4 mx-1 button-menu m-3"
                  title="Reset canvas"
                  @click.stop.prevent="onResize()"
                >
                  <a>
                    <span class="material-icons"> aspect_ratio </span>
                  </a>
                </div> -->
                  <!-- RANGE SLIDER -->
                  <div style="width: 150px; margin-top: 25px">
                    <div class="range-slider">
                      <input
                        v-model="sliderVal"
                        class="input-range"
                        orient="vertical"
                        type="range"
                        step="0.2"
                        value="1"
                        min="1"
                        max="2.2"
                        @change="changeFoo"
                      />
                    </div>
                  </div>
                  <div style="position: relative">
                    <compact-picker
                      v-if="chooseColor"
                      v-model="colors"
                      @input="changeColor"
                      :value="colors"
                      style="position: absolute"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="row" style="max-width: 700px">
                <div
                  v-for="(field, index) in subInspectionFields[1]"
                  :key="index"
                  :class="{'col-md-6': field.fieldtype !== 'location'}"
                >
                  <!-- SELECT -->

                  <app-form-field
                    v-if="field.fieldtype === 'photo'"
                    :class="{showLatLng: showImage}"
                    v-bind:id="field.id"
                    v-bind:type="field.fieldtype"
                    v-bind:options="field.options"
                    v-bind:label="field.fieldlabel"
                    v-model="field.fieldlabel"
                  ></app-form-field>

                  <!-- LOCATION -->
                  <div v-if="field.fieldtype === 'location'" class="row">
                    <app-form-field
                      ref="lat"
                      class="col-md-6"
                      v-bind:id="field.id"
                      v-bind:type="'text'"
                      v-bind:options="field.options"
                      v-bind:label="'Lat:'"
                      v-bind:fieldValue="point[1]"
                      v-model="field.fieldlabel"
                    ></app-form-field>
                    <app-form-field
                      ref="lng"
                      class="col-md-6"
                      v-bind:id="field.id"
                      v-bind:type="'text'"
                      v-bind:options="field.options"
                      v-bind:fieldValue="point[0]"
                      v-bind:label="'Lng:'"
                      v-model="field.fieldlabel"
                    ></app-form-field>
                  </div>
                  <!-- OTHER FIELDS -->
                  <app-form-field
                    v-if="
                      field.fieldtype !== 'location' &&
                      field.fieldtype !== 'photo'
                    "
                    v-bind:id="field.id"
                    v-bind:type="field.fieldtype"
                    v-bind:options="field.options"
                    v-bind:label="field.fieldlabel"
                    v-bind:fieldValue="getValue(field.id)"
                    v-model="field.fieldlabel"
                  ></app-form-field>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- BUTTONS -->
    <div class="row p-2 justify-content-center" style="top: 85%">
      <button
        @click.stop.prevent="onClose"
        class="btn btn-secondary m-2 rounded w-auto"
      >
        Close
      </button>
      <button
        @click.stop.prevent="onSave"
        class="btn btn-primary m-2 rounded w-auto"
      >
        Save
      </button>
    </div>
  </div>
</template>

// SCRIPT

<script>
import {mapGetters} from 'vuex'
import html2canvas from 'html2canvas'
import {getterTypes as inspectionGetterTypes} from '@/store/modules/inspections'
import {mutationTypes as inspectionsMutationTypes} from '@/store/modules/inspections'
import {actionTypes} from '@/store/modules/inspections'
import {getterTypes as mapGetterTypes} from '@/store/modules/map'
import uniqid from 'uniqid'
import {getterTypes as playerGetterTypes} from '@/store/modules/player'
import AppFormField from '@/components/inspections/Field.vue'
import {Compact} from 'vue-color'

export default {
  name: 'EditSubForm',
  props: ['new'],
  data() {
    return {
      colors: {h: 150, s: 0.66, v: 0.3},
      fields: [],
      imageStr: '',
      imageFile: null,
      showImage: true,
      isNew: this.new,
      flag: false,
      prevX: 0,
      prevY: 0,
      currX: 0,
      currY: 0,
      dot_flag: false,
      ctx: null,
      color: 'red',
      y: 2,
      freeDraw: false,
      circleDraw: false,
      startX: 0,
      startY: 0,
      isDown: false,
      imageCanvas: null,
      canvasShadow: null,
      ctxShadow: null,
      writeText: false,
      writeTextClick: false,
      chooseColor: false,
      canvasImage: null,
      m_pos: null,
      currentResizer: null,
      resizeClicked: false,
      canvasWidth: '810px',
      canvasHeight: '410px',
      sliderVal: 0,
      prevScale: 1,
    }
  },
  computed: {
    ...mapGetters({
      selectedGeneralInspection:
        inspectionGetterTypes.selectedGeneralInspection,
      generalInspectionFields: inspectionGetterTypes.generalInspectionFields,
      subInspectionFields: inspectionGetterTypes.subInspectionFields,
      inspections: inspectionGetterTypes.inspections,
      isLoading: inspectionGetterTypes.isLoading,
      save: inspectionGetterTypes.save,
      image: playerGetterTypes.image,
      url: playerGetterTypes.url,
      pointerPos: mapGetterTypes.pointerPos,
      objectClicked: mapGetterTypes.objectClicked,
      generalInspectionSaved: inspectionGetterTypes.generalInspectionSaved,
      selectedCustomInspection: inspectionGetterTypes.selectedCustomInspection,
    }),
    point() {
      const myRe = /\((.*?)\)/g
      const arr = myRe.exec(this.selectedCustomInspection.data['316'])
      let points = arr[1].split(' ')
      return points
    },
  },
  methods: {
    changeFoo(v) {
      this.scale(this.sliderVal)
    },
    percentageDec(total, per) {
      return total - (per / 100) * total
    },
    percentageInc(total, per) {
      return total + (per / 100) * total
    },
    reversePercentage(finalNum, per) {
      return (finalNum * 100) / (per + 100)
    },
    scale(scale) {
      // CALCULATE INCREASE
      let increase
      if (scale > this.prevScale) {
        increase = Math.round((scale - this.prevScale) * 10) / 10 + 1
      } else if (scale < this.prevScale) {
        increase = 1 - Math.round((this.prevScale - scale) * 10) / 10
      } else {
        increase = scale
      }

      // SET WIDTH AND HEIGHT
      let width = this.$refs.myCanvas.width
      let height = this.$refs.myCanvas.height
      let imageData = this.ctx.getImageData(0, 0, width, height)

      // SET SECOND SHADOW CANVAS
      this.canvasShadow.getContext('2d').putImageData(imageData, 0, 0)
      let scaleCanvas = document.createElement('canvas')
      if (increase < 1) {
        scaleCanvas.width = Math.round(
          this.reversePercentage(width, (1 - increase) * 100)
        )
        scaleCanvas.height = Math.round(
          this.reversePercentage(height, (1 - increase) * 100)
        )
      } else {
        scaleCanvas.width = width * increase
        scaleCanvas.height = height * increase
      }

      // SCALE CONTEXT
      let scaleCtx = scaleCanvas.getContext('2d')
      if (increase < 1) {
        var decreaseValue = this.$refs.myCanvas.width - scaleCanvas.width
        let newScale = 1 - decreaseValue / this.$refs.myCanvas.width
        scaleCtx.scale(newScale, newScale)
      } else {
        scaleCtx.scale(increase, increase)
      }
      scaleCtx.drawImage(this.$refs.myCanvas, 0, 0)
      let scaledImageData = scaleCtx.getImageData(
        0,
        0,
        scaleCanvas.width,
        scaleCanvas.height
      )

      // SCALE MY CANVAS
      if (increase < 1) {
        this.$refs.myCanvas.width = Math.round(
          this.reversePercentage(width, (1 - increase) * 100)
        )
        this.$refs.myCanvas.height = Math.round(
          this.reversePercentage(height, (1 - increase) * 100)
        )
      } else {
        this.$refs.myCanvas.width = width * increase
        this.$refs.myCanvas.height = height * increase
      }

      this.ctx.putImageData(scaledImageData, 0, 0)
      this.saveCanvas()

      // SCALE SHADOW CANVAS
      if (increase < 1) {
        this.canvasShadow.width = Math.round(
          this.reversePercentage(width, (1 - increase) * 100)
        )
        this.canvasShadow.height = Math.round(
          this.reversePercentage(height, (1 - increase) * 100)
        )
      } else {
        this.canvasShadow.width = width * increase
        this.canvasShadow.height = height * increase
      }
      this.ctxShadow.putImageData(scaledImageData, 0, 0)
      this.prevScale = scale
    },
    onResize() {
      this.saveCanvas()
      this.resizeClicked = !this.resizeClicked
      this.canvasWidth = this.$refs.myCanvas.width + 10 + 'px'
      this.canvasHeight = this.$refs.myCanvas.height + 10 + 'px'
      if (this.resizeClicked) {
        this.$refs.canvasContainer.style.position = 'relative'
      } else {
        this.$refs.canvasContainer.style.position = ''
      }
    },
    onResizeDown(e) {
      this.currentResizer = e.target
      this.prevX = e.clientX
      this.prevY = e.clientY
      window.addEventListener('mousemove', this.mousemove)
      window.addEventListener('mouseup', this.mouseup)
    },
    mousemove(e) {
      const rect = this.$refs.rect.getBoundingClientRect()
      let width = 0
      let height = 0
      if (this.currentResizer.classList.contains('se')) {
        width = rect.width - (this.prevX - e.clientX)
        height = width / 2
        this.$refs.rect.style.width = width + 'px'
        this.$refs.rect.style.height = height + 'px'

        // Increase = New Number - Original Number
        // Then:  divide the increase by the original number and multiply the answer by 100.
        // % increase = Increase ÷ Original Number × 100.
        // If your answer is a negative number, then this is a percentage decrease.

        let increase = width - 800
        let percent = increase / 800 + 1
        this.scale(percent)
      }
      this.prevX = e.clientX
      this.prevY = e.clientY
    },
    mouseup(e) {
      window.removeEventListener('mousemove', this.mousemove)
      window.removeEventListener('mousemove', this.mouseup)
    },

    onResizeMove(e) {},
    onResizeUp(e) {},
    onRefresh() {
      this.ctx.clearRect(
        0,
        0,
        this.$refs.myCanvas.width,
        this.$refs.myCanvas.height
      )
      this.ctxShadow.clearRect(
        0,
        0,
        this.$refs.myCanvas.width,
        this.$refs.myCanvas.height
      )
      this.ctx.drawImage(
        this.imageCanvas,
        0,
        0,
        this.$refs.myCanvas.width,
        this.$refs.myCanvas.height
      )
    },
    showColorpicker() {
      this.chooseColor = true
    },
    changeColor(e) {
      this.color = e.hex
      this.chooseColor = false
    },
    onWriteText() {
      this.writeText = !this.writeText
      this.circleDraw = false
      this.freeDraw = false
    },
    onFreeDraw() {
      this.freeDraw = !this.freeDraw
      this.circleDraw = false
      this.writeText = false
    },
    onCircleDraw() {
      this.$refs.myCanvas.getContext('2d').save()
      this.circleDraw = !this.circleDraw
      this.freeDraw = false
      this.writeText = false
    },
    onRemoveImage() {
      this.showImage = false
    },
    Base64ToImage(base64img, callback) {
      var img = new Image()
      img.onload = function () {
        callback(img)
      }
      img.src = base64img
    },
    fieldTypeConverter(field) {
      switch (field) {
        case 'textarea':
        case 'text':
          return 'text'
        case 'number':
          return 'number'
        case 'select-one':
          return 'select'
        case 'file':
          return 'photo'
      }
    },
    onClose() {
      this.$store.commit(inspectionsMutationTypes.editSubClicked)
    },
    canvasMouseMove(e) {
      this.findxy('move', e)
    },
    canvasMouseDown(e) {
      this.prevX = e.clientX
      this.prevY = e.clientY
      if (this.writeText) {
        this.addTextField(e)
      } else {
        this.findxy('down', e)
      }
    },
    canvasMouseUp(e) {
      this.findxy('up', e)
      this.saveCanvas()
    },
    canvasMouseOut(e) {
      this.findxy('out', e)
      const img = this.$refs.myCanvas.toDataURL('image/png')
      fetch(img)
        .then((res) => res.blob())
        .then((blob) => {
          this.canvasImage = new File([blob], 'Screenshot.png', {
            type: 'image/png',
          })
        })
    },
    findxy(res, e) {
      let scrollTop = this.$refs.container.scrollTop
      if (res == 'down') {
        if (this.freeDraw) {
          this.prevX = this.currX
          this.prevY = this.currY
          this.currX = e.clientX - this.$refs.myCanvas.offsetLeft
          this.currY = e.clientY - this.$refs.myCanvas.offsetTop + scrollTop

          this.flag = true
          this.dot_flag = true
          if (this.dot_flag) {
            this.ctx.beginPath()
            this.ctx.fillStyle = this.color
            this.ctx.fillRect(this.currX, this.currY, 2, 2)
            this.ctx.closePath()
            this.dot_flag = false
          }
        }
        if (this.circleDraw) {
          this.startX = parseInt(e.clientX - this.$refs.myCanvas.offsetLeft)
          this.startY = parseInt(
            e.clientY - this.$refs.myCanvas.offsetTop + scrollTop
          )
          this.flag = true
        }
      }
      if (res == 'up' || res == 'out') {
        this.flag = false
      }
      if (res == 'move') {
        if (this.freeDraw) {
          if (this.flag) {
            this.prevX = this.currX
            this.prevY = this.currY
            this.currX = e.clientX - this.$refs.myCanvas.offsetLeft
            this.currY = e.clientY - this.$refs.myCanvas.offsetTop + scrollTop
            this.draw()
          }
        }
        if (this.circleDraw) {
          if (this.flag) {
            this.resetCanvas()
            this.mouseX = parseInt(e.clientX - this.$refs.myCanvas.offsetLeft)
            this.mouseY = parseInt(
              e.clientY - this.$refs.myCanvas.offsetTop + scrollTop
            )
            this.ctx.strokeStyle = this.color
            this.ctx.lineWidth = this.y
            this.ctx.beginPath()
            this.ctx.moveTo(
              this.startX,
              this.startY + (this.mouseY - this.startY) / 2
            )
            this.ctx.bezierCurveTo(
              this.startX,
              this.startY,
              this.mouseX,
              this.startY,
              this.mouseX,
              this.startY + (this.mouseY - this.startY) / 2
            )
            this.ctx.bezierCurveTo(
              this.mouseX,
              this.mouseY,
              this.startX,
              this.mouseY,
              this.startX,
              this.startY + (this.mouseY - this.startY) / 2
            )
            this.ctx.closePath()
            this.ctx.stroke()
          }
        }
      }
    },
    draw() {
      this.ctx.beginPath()
      this.ctx.moveTo(this.prevX, this.prevY)
      this.ctx.lineTo(this.currX, this.currY)
      this.ctx.strokeStyle = this.color
      this.ctx.lineWidth = this.y
      this.ctx.stroke()
      this.ctx.closePath()
    },
    addTextField(e) {
      let scrollTop = this.$refs.container.scrollTop

      this.writeText = false
      this.currX = e.clientX - this.$refs.myCanvas.offsetLeft
      this.currY = e.clientY - this.$refs.myCanvas.offsetTop + scrollTop
      this.writeTextClick = true
      setTimeout(() => {
        let textField = this.$refs.textField
        textField.style.font = '24px sans-serif'
        textField.style.fontWeight = 'bold'
        textField.style.color = this.color
        textField.style.top = `${e.clientY - 22}px`
        textField.style.left = `${e.clientX - 10}px`
        textField.style.display = `inline`
        textField.focus()
      }, 100)
    },
    saveTextField() {
      const input = this.$refs.textField.value
      this.writeTextClick = false
      this.ctx.font = 'bold 24px sans-serif'
      this.ctx.fillStyle = this.color
      this.ctx.fillText(input, this.currX, this.currY)
      this.canvasMouseOut()
    },
    saveCanvas() {
      this.ctxShadow.clearRect(
        0,
        0,
        this.canvasShadow.width,
        this.canvasShadow.height
      )
      this.ctxShadow.drawImage(this.$refs.myCanvas, 0, 0)
    },

    resetCanvas() {
      let width = this.$refs.myCanvas.width
      let height = this.$refs.myCanvas.height
      this.ctx.clearRect(0, 0, width, height)
      this.ctx.drawImage(this.imageCanvas, 0, 0, width, height)
      this.ctx.drawImage(this.canvasShadow, 0, 0)
    },
    getValue(id) {
      let empty = ''
      for (const [key, value] of Object.entries(
        this.selectedCustomInspection.data
      )) {
        if (key === id) {
          if (value !== 'null') {
            empty = value
          }
        }
      }
      return empty
    },
    onSave() {
      let lat = null
      let lng = null
      const result = []
      let inspection_fields = {}
      for (var i = 0; i < this.$refs.form.length; i++) {
        if (
          this.$refs.form[i].name !== 'Lat:' &&
          this.$refs.form[i].name !== 'Lng:'
        ) {
          let value = null
          if (this.$refs.form[i].type === 'file') {
            if (this.showImage) {
              value = this.canvasImage
            } else {
              value = this.$refs.form[i].files[0]
            }
          } else {
            value = this.$refs.form[i].value
          }
          inspection_fields[this.$refs.form[i].id] = value
          result.push({
            type: this.fieldTypeConverter(this.$refs.form[i].type),
            value: value,
            row_id: this.$refs.form[i].id,
            id: uniqid(),
          })
        } else if (this.$refs.form[i].name === 'Lat:') {
          lat = this.$refs.form[i].value
        } else if (this.$refs.form[i].name === 'Lng:') {
          lng = this.$refs.form[i].value
        }
      }
      inspection_fields['316'] = `POINT(${lng} ${lat})`
      result.push({
        type: 'location',
        value: `POINT(${lng} ${lat})`,
        row_id: '316',
        id: uniqid(),
      })
      this.$store
        .dispatch(actionTypes.addInspection, [
          this.objectClicked.id,
          result,
          '8',
          this.selectedGeneralInspection,
          'edit',
          this.selectedCustomInspection.insp_order,
        ])
        .then((r) => {
          this.$store.commit(inspectionsMutationTypes.editCustomInspection, [
            this.selectedCustomInspection.insp_order,
            inspection_fields,
          ])
          this.$store.commit(inspectionsMutationTypes.save, null)
        })
    },
  },
  components: {
    AppFormField,
    'compact-picker': Compact,
  },
  mounted() {
    if (this.selectedCustomInspection.data['315']) {
      if (this.selectedCustomInspection.data['315'].value) {
        this.showImage = true
        this.imageStr = this.selectedCustomInspection.data['315'].url
        var fileimg = document.createElement('img')
        fileimg.src = this.imageStr
        fileimg.crossOrigin = 'anonymous'
        fileimg.onload = (r) => {
          this.imageCanvas = fileimg
          this.ctx.drawImage(fileimg, 0, 0, 800, 400)
          this.ctx.save()
        }
      }
      if (this.selectedCustomInspection.data['315'] instanceof File) {
        this.showImage = true
        this.imageStr = URL.createObjectURL(
          this.selectedCustomInspection.data['315']
        )
        this.canvasImage = new File([this.imageStr], 'Screenshot.png', {
          type: 'image/png',
        })
        var img = document.createElement('img')
        img.src = this.imageStr
        img.crossOrigin = 'anonymous'
        img.onload = (r) => {
          this.imageCanvas = img
          this.ctx.drawImage(img, 0, 0, 800, 400)
          this.ctx.save()
        }
      }
    }

    this.canvasShadow = this.$refs.myCanvas.cloneNode()
    this.ctxShadow = this.canvasShadow.getContext('2d')
    this.ctx = this.$refs.myCanvas.getContext('2d')
  },
}
</script>
<style scoped>
.inactive {
  background-color: white;
}
.activeIcon {
  background-color: rgb(173, 173, 247);
}
.showLatLng {
  display: none;
}

.item {
  position: absolute;
  top: -3px;
  left: -3px;

  border: 1px dashed black;
}
.resizer {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  z-index: 2;
}
.resizer.nw {
  top: -1px;
  left: -1px;
  cursor: nw-resize;
}
.resizer.ne {
  top: -1px;
  right: -1px;
  cursor: ne-resize;
}
.resizer.sw {
  bottom: -1px;
  left: -1px;
  cursor: sw-resize;
}
.resizer.se {
  bottom: -1px;
  right: -1px;
  cursor: se-resize;
}
</style>
