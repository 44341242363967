<template>
  <div class="auth-page">
    <div class="container page">
      <div class="row" style="padding-top: 15vh">
        <div class="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12">
          <img
            src="@/assets/images/iASSET-01.svg"
            alt="logo"
            style="
              display: block;
              margin-left: auto;
              margin-right: auto;
              width: 90%;
              padding-bottom: 30px;
            "
          />
          <app-validation-errors
            v-if="validationErrors"
            :validation-errors="validationErrors"
          ></app-validation-errors>
          <form @submit.prevent="onSubmit">
            <div class="field mt-3">
              <input
                v-model="email"
                type="text"
                placeholder=" "
                class="form-field form-control-lg input-text"
              />
              <label>Gebruikersnaam</label>
            </div>
            <div class="field mt-3">
              <input
                v-model="password"
                placeholder=" "
                :type="type"
                class="form-field form-control-lg input-text"
              />
              <label>Wachtwoord</label>
              <span
                class="material-icons dark-blue me-2 eye"
                @click="passwordVisible()"
                >visibility</span
              >
            </div>
            <div class="field mt-3">
              <input
                v-model="domain"
                placeholder=" "
                type="text"
                class="form-field form-control-lg input-text"
              />
              <label>Domein</label>
            </div>
            <button
              class="btn btn-lg mt-3 rounded-4 shadow"
              style="
                background: #2582cc;
                color: white;
                width: 100%;
                height: 70px;
              "
              :disabled="isSubmitting"
            >
              Inloggen
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import AppValidationErrors from '@/components/ValidationErrors.vue'
import {actionTypes} from '@/store/modules/auth'
import md5 from 'md5'
import {mutationTypes} from '@/store/modules/auth'

import {setItem} from '@/helpers/persistanceStorage'
export default {
  name: 'AppSignIn',
  components: {
    AppValidationErrors,
  },
  data() {
    return {
      email: '',
      password: '',
      domain: '',
      type: 'password',
      visible: false,
    }
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.auth.isSubmitting,
      validationErrors: (state) => state.auth.validationErrors,
    }),
  },
  methods: {
    passwordVisible() {
      this.visible = !this.visible
      if (this.visible) {
        this.type = 'text'
      } else {
        this.type = 'password'
      }
    },
    onSubmit() {
      const md5pass = md5(this.password)
      this.$store
        .dispatch(actionTypes.signIn, {
          domain: this.domain,
          username: this.email,
          password: md5pass,
          version: '9.4',
        })
        .then((r) => {
          setItem('curr-domain', this.domain);
          setItem('user', r.data.returndata[0].username);
          if (r.data.returndata[0].access === 'approved') {
            this.$store.commit(mutationTypes.domain, this.domain)
            this.$store.commit(mutationTypes.validationErrors, null)
            this.$router.push({name: 'home'})
          } else {
            this.$store.commit(
              mutationTypes.validationErrors,
              r.data.returndata[0].error_msg
            )

            // this.validationErrors = r.data.returndata[0].error_msg
          }
        })
    },
  },
}
</script>
<style scoped>
.field {
  position: relative;
}
.form-field {
  width: 100%;
  height: 70px;
  border-radius: 5px !important;
  text-align: center;
  padding-top: 20px;
}
label {
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 23px;
  transition: 0.2s ease all;
  font-size: 19px;
  color: grey;
}
input:focus ~ label,
input:not(:placeholder-shown) ~ label {
  top: 8px;
  font-size: 13px;
  color: rgb(181, 181, 181);
}
::placeholder {
  text-align: center;
}
.auth-page {
  position: relative;
  width: 100vw;
  height: 100vh;
}
.eye {
  position: absolute;
  top: 40px;
  left: 90%;
  cursor: pointer;
}
</style>
